<template xmlns="http://www.w3.org/1999/html">
        <div class="messageContent">
                <div class="messageBody">
                        <!-- 文本域 -->
                        <textarea name="content" autofocus="autofocus" rows="6" cols="40" v-model="messageContent" id="mes"
                                  wrap="hard" :placeholder="bakcContent" class="message_content" ></textarea>
                        <!-- 留言人和邮箱 -->
                        <ul>
                            <li>
                                <svg class="icon" aria-hidden="true">
                                    <use xlink:href="#icon-rentou"></use>
                                </svg>
                                <input type="text" v-model="messageUser" placeholder="昵称" autocomplete="on" class="user_content">
                            </li>
                            <li>
                                <svg class="icon" aria-hidden="true">
                                    <use xlink:href="#icon-youjian"></use>
                                </svg>
                                <input type="text" v-model="messageMail" placeholder="电子邮件" autocomplete="on" class="email_content">
                            </li>
                            <li class="submit_button">
                                <button  class="button_content" @click="addMessage()">
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-fabu"></use>
                                    </svg>提交
                                </button>
                            </li>
                        </ul>
                        <br>
                        <hr>
                        <br>
                        <!-- 留言信息 -->
                        <div class="concrete_content" v-for="(item,index) in messageList" :key="index">
                            <!-- 主留言 -->
                            <div class="main_content_img">
                                <img class="headTou"  :src="item.mainMessage.messageUser=='FQZ'?'http://img.fuqz.cc/head.jpg':'http://img.fuqz.cc/head1.jpg'" alt="">
                                <div class="main_content">
                                        <h4 v-if="item.mainMessage.messageUser=='FQZ'" style="color: #ff8000">
                                            <svg class="icon" aria-hidden="true">
                                                <use xlink:href="#icon-zhuren"></use>
                                            </svg>博主</h4>
                                        <h4 v-if="item.mainMessage.messageUser!='FQZ'">{{item.mainMessage.messageUser}}</h4>&nbsp;<span>{{item.mainMessage.messageDt}}</span>
                                    <p class="message-ping">{{item.mainMessage.messageContent}}</p>
                                    <p class="message_replay">  <a href="javascript:void(0)" @click="focusReply(item.mainMessage.id,item.mainMessage.messageUser)">回复</a></p>
                                </div>
                            </div>
                            <div class="vice_content_img" v-for="(values,index) in item.replayMessage" :key="index">
                                <img class="vice_headTou" :src="values.replyUser=='FQZ'?'http://img.fuqz.cc/head.jpg':'http://img.fuqz.cc/head1.jpg'" alt="">
                                <div class="main_content">
                                    <h4 v-if="values.replyUser=='FQZ'" style="color: #ff8000">
                                        <svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-zhuren"></use>
                                        </svg>博主
                                    </h4>
                                    <h4 v-if="values.replyUser!='FQZ'">{{values.replyUser}}</h4>
                                    <h4 v-if="values.replyMessageUser=='FQZ'" style="color: #ff8000">
                                        @&nbsp;<svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-zhuren"></use>
                                        </svg>博主
                                    </h4>
                                    <h4 v-if="values.replyMessageUser!='FQZ'"  style="color: #00B5AD">@&nbsp;{{values.replyMessageUser}}</h4>
                                    <span>{{values.replyDt}}</span>
                                    <p class="message-ping">{{values.replyContent}}</p>
                                    <p class="message_replay"> <a href="javascript:void(0)" @click="focusReply(item.mainMessage.id,values.replyUser)">回复</a></p>
                                </div>
                            </div>
                        </div>
                    <el-pagination
                            background
                            @current-change="handleCurrentChange"
                            :page-size="5"
                            layout="prev, pager, next"
                            :total="total">
                    </el-pagination>
                </div>
        </div>
</template>

<script>
    export default {
        name: "messageContent",
        data(){
            return {
                /* 总条数 */
                total:0,
                condition:{
                    pagesize: 5,
                    /* 当前页 */
                    pagenum: 1
                },
                /* 提交留言信息*/
                messageContent:'',
                messageUser:'',
                messageMail:'',
                /* 留言板背景内容 */
                bakcContent:"请输入留言信息...",
                /* 留言内容 */
                messageList:[],
                /* 回复用户和id*/
                id:0,
                replyUserName:''
            }
        },
        methods:{
            /* 当前页发生变化的时候 */
            handleCurrentChange(val){
                this.condition.pagenum = val;
                this.findAllMessage()
            },
            /* 添加留言 */
            async addMessage(){
                if(this.id!=0){
                    if(this.messageMail=='' || this.messageUser=='' || this.messageMail==''){
                        this.$message.warning("请将信息填写完整")
                        return;
                    }
                    const data = {"messageContent":this.messageContent,"messageUser":this.messageUser,"messageMail":this.messageMail,"id":this.id,"replyUserName":this.replyUserName}
                    const res = await this.$blogmessage.addReplyMessage(data)
                    if(res.code!=100) return this.$message.error(res.msg)
                    this.messageContent = ''
                    this.messageUser = ''
                    this.messageMail = ''
                    this.$message.success("留言成功,等待审核")
                }else{
                    if(this.messageMail=='' || this.messageUser=='' || this.messageMail==''){
                        this.$message.warning("请将信息填写完整")
                        return;
                    }
                    const data = {"messageContent":this.messageContent,"messageUser":this.messageUser,"messageMail":this.messageMail}
                    const res = await this.$blogmessage.addMesage(data)
                    if(res.code!=100) return this.$message.error(res.msg)
                    this.messageContent = ''
                    this.messageUser = ''
                    this.messageMail = ''
                    this.$message.success("留言成功,等待审核")
                }
            },
            /* 查询所有留言 */
            async findAllMessage(){
                const res = await this.$blogmessage.findAllMessage(this.condition)
                this.messageList = res.data.list
                this.total = res.data.total
            },
            /* 回复留言 */
            focusReply(id,value){
                document.getElementById("mes").focus()
                this.bakcContent = "@:"+value
                this.replyUserName = value
                this.id=id
            },

        },
        created() {
            this.findAllMessage()
            this.$store.commit("updateRotation",false)
            /* 隐藏左侧 */
            this.$store.commit("updateLeftShow",true)
        }
    }
</script>

<style scoped>

</style>
